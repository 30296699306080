import produce from 'immer';
import { createContext, FC, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { calculateInclVat } from 'utils/numberHelper';
import { getProductAttribute } from 'utils/productHelper';
import { Coupon } from 'hooks/api/coupon/types';
import { nonOrganisationPriceMultiplier } from 'utils/constants';
import i18n from 'i18n';
import { useSession } from 'contexts/sessionContext';
import { isOrganisation } from 'utils/organisationHelper';
import React from 'react';
import { setCookie } from 'utils/cookieHelper';
import { itemCookieName, priceCookieName } from 'utils/constants/cookieConstants';
import { numberToCurrency } from 'utils/stringHelper';

export interface CartState {
    products: CartProductLine[];
    service: boolean;
    coupon?: Coupon;
    organisation?: boolean;
    locale: string;
    cartImage?: string;
}

export interface CartProductLine {
    id: string;
    quantity: number;
    product: Product;
}

interface Attribute {
    description: string;
    attribute_id: number;
    attribute_name?: string;
    attribute_value_name?: string;
    attribute_value?: any;
    attribute_value_key?: string;
    attribute_key?: string;
    visible_in_overview: boolean;
    visible?: boolean;
}

export interface Product {
    articles: any[];
    attributes: Attribute[];
    catalogue_id: number;
    configuration: { attributes: Attribute[]; materials: any[]; };
    price: { base: number };
    replication_type: string;
    thumbnail: string;
    bundle_uuid: string;
    name?: string;
}

export interface CartHelpers {
    addProduct: (product: Product, quantity: number) => void;
    updateProduct: (product: Product, quantity: number, id: string) => void;
    removeProduct: (id: string) => void;
    addProductQuantity: (id: string) => void;
    removeProductQuantity: (id: string) => void;
    setService: (service: boolean) => void;
    applyCoupon: (coupon: Coupon) => void;
    setOrganisation: (organisation: boolean) => void;
    setLocale: (language: string) => void;
    setCartImage: (imageBase64: string) => void;
    clearCart: () => void;
}

export type CartContextType =
    CartHelpers & {
        cart?: CartState;
        totalProductPrice: number;
        servicePrice: number;
        servicePriceInclVat: number;
        servicePriceMonthly: number;
        servicePriceMonthlyInclVat: number;
        totalPrice: number;
        totalPriceInclVat: number;
        seatingCount: number;
        imagePreparationPrice: number;
        discount: number;
    };

export type CartAction =
    | { type: 'SET_CART'; }
    | { type: 'ADD_PRODUCT'; payload: { product: Product, quantity: number } }
    | { type: 'UPDATE_PRODUCT'; payload: { id: string, product: Product, quantity: number } }
    | { type: 'REMOVE_PRODUCT'; payload: { id: string } }
    | { type: 'ADD_PRODUCT_QUANTITY'; payload: { id: string } }
    | { type: 'REMOVE_PRODUCT_QUANTITY'; payload: { id: string } }
    | { type: 'SET_SERVICE'; payload: { service: boolean } }
    | { type: 'APPLY_COUPON'; payload: { coupon: Coupon } }
    | { type: 'SET_ORGANISATION'; payload: { organisation: boolean } }
    | { type: 'SET_LOCALE'; payload: { locale: string } }
    | { type: 'SET_CART_IMAGE'; payload: { imageBase64: string } }
    | { type: 'CLEAR_CART'; };

const initialCart: CartState = {
    products: [],
    service: false,
    cartImage: undefined,
    locale: i18n.language
};

const cartKeyLocalStorage = 'cart2';

function cartReducer(
    state: CartState | undefined,
    action: CartAction
): CartState | undefined {
    switch (action.type) {
        case 'SET_CART': {
            const localCartString = localStorage.getItem(cartKeyLocalStorage);
            const localCart = localCartString ? JSON.parse((localCartString)) as CartState : initialCart;
            state = localCart;

            return state;
        }
        case 'ADD_PRODUCT': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.products.push({
                    id: uuid(),
                    product: action.payload.product,
                    quantity: action.payload.quantity
                });
            });

            return nextState;
        }
        case 'UPDATE_PRODUCT': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                const product = s.products.find(p => p.id === action.payload.id);

                if (product) {
                    product.product = action.payload.product;
                    product.quantity = action.payload.quantity;
                } else {
                    // add new cart item if product could not be found
                    s.products.push({
                        id: uuid(),
                        product: action.payload.product,
                        quantity: action.payload.quantity
                    });
                }
            });

            return nextState;
        }
        case 'REMOVE_PRODUCT': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                const index = s.products.findIndex(p => p.id === action.payload.id);

                s.products.splice(index, 1);
            });

            return nextState;
        }
        case 'ADD_PRODUCT_QUANTITY': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {

                const product = s.products.find(p => p.id === action.payload.id);

                if (product) {
                    product.quantity++;
                }
            });

            return nextState;
        }
        case 'REMOVE_PRODUCT_QUANTITY': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                const product = s.products.find(p => p.id === action.payload.id);

                if (product) {
                    if (product.quantity > 1) {
                        product.quantity--;
                    } else {
                        const index = s.products.findIndex(p => p.id === action.payload.id);
                        s.products.splice(index, 1);
                    }
                }
            });

            return nextState;
        }
        case 'SET_SERVICE': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.service = action.payload.service;
            });

            return nextState;
        }
        case 'APPLY_COUPON': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.coupon = action.payload.coupon;
            });

            return nextState;
        }
        case 'SET_ORGANISATION': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.organisation = action.payload.organisation;
            });

            return nextState;
        }
        case 'CLEAR_CART': {
            localStorage.removeItem(cartKeyLocalStorage);
            state = initialCart;

            return state;
        }
        case 'SET_LOCALE': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.locale = action.payload.locale;
            });

            return nextState;
        }
        case 'SET_CART_IMAGE': {
            if (state == null) {
                throw new Error('Action not possible before cart is set');
            }

            const nextState = produce(state, s => {
                s.cartImage = action.payload.imageBase64;
            });

            return nextState;
        }

        default:
            return state;
    }
}

const CartContext = createContext<CartContextType>({} as any);

export const CartProvider: FC = ({ children }) => {

    const [cart, dispatch] = useReducer<Reducer<CartState | undefined, CartAction>>(
        cartReducer,
        undefined
    );

    const { session } = useSession();

    const priceMultiplier = useMemo(() => {
        if (isOrganisation(cart, session)) {
            return 1;
        }

        return nonOrganisationPriceMultiplier;
    }, [cart, session, isOrganisation]);

    const itemCount = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        const items = cart.products.map((cp) => cp.quantity);
        const itemCount = items?.reduce((c, p) => c + p, 0) ?? 0;

        return itemCount;
    }, [cart?.products]);

    const seatingCount = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        const seatings = cart.products.map((cp) => cp.quantity * Number(getProductAttribute(cp.product, 'zitplaats')?.attribute_value_name ?? '1'));
        const seatingCount = seatings?.reduce((c, p) => c + p, 0) ?? 0;

        return seatingCount;
    }, [cart?.products]);

    const imagesCount = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        let images = cart.products.map((cp) =>
            cp.product.configuration.attributes.map((att) =>
                att.visible === true ? att.attribute_value.image_source : ''
            ));

        images = images.flat().filter((img) => img != null && img !== '');

        return new Set(images).size;
    }, [cart?.products, priceMultiplier]);

    const totalProductPrice = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        return cart.products.reduce((a, b) =>
            a + b.product.price.base * b.quantity, 0) * priceMultiplier;
    }, [cart?.products, priceMultiplier]);

    const servicePrice = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        return 15 * seatingCount * priceMultiplier; // 15 is the price per seat per year
    }, [cart?.service, seatingCount, priceMultiplier]);

    const servicePriceInclVat = useMemo(() => {
        return calculateInclVat(servicePrice);
    }, [servicePrice]);

    const servicePriceMonthly = useMemo(() => {
        if (cart == null) {
            return 0;
        }

        return 1.25 * seatingCount * priceMultiplier; // 1.25 is the price per seat per year
    }, [cart?.service, seatingCount, priceMultiplier]);

    const servicePriceMonthlyInclVat = useMemo(() => {
        return calculateInclVat(servicePriceMonthly);
    }, [servicePrice]);

    const imagePreparationPrice = useMemo(() => {
        return imagesCount * 100 * priceMultiplier;
    }, [imagesCount, priceMultiplier]);

    const discount = useMemo(() => {
        return (totalProductPrice + imagePreparationPrice) * (cart?.coupon?.percentage ?? 0);
    }, [totalProductPrice, imagePreparationPrice, cart]);

    const totalPrice = useMemo(() => {
        return totalProductPrice + imagePreparationPrice - discount;
    }, [totalProductPrice, imagePreparationPrice, discount]);

    const totalPriceInclVat = useMemo(() => {
        return calculateInclVat(totalPrice);
    }, [totalPrice]);

    useEffect(() => {
        if (cart == null) {
            dispatch({
                type: 'SET_CART'
            });
            return;
        }

        localStorage.setItem(cartKeyLocalStorage, JSON.stringify(cart));
    }, [cart]);

    useEffect(() => {
        setCookie(itemCookieName, itemCount.toString());
    }, [itemCount]);

    useEffect(() => {
        setCookie(priceCookieName, numberToCurrency(isOrganisation(cart, session) ? totalPrice : totalPriceInclVat));
    }, [totalPriceInclVat, cart, session, isOrganisation]);

    const addProduct = useCallback((product: Product, quantity: number) => {
        dispatch({
            type: 'ADD_PRODUCT',
            payload: {
                product,
                quantity
            }
        });
    }, [dispatch]);

    const updateProduct = useCallback((product: Product, quantity: number, id: string) => {
        dispatch({
            type: 'UPDATE_PRODUCT',
            payload: {
                id,
                product,
                quantity
            }
        });
    }, [dispatch]);

    const removeProduct = useCallback((id: string) => {
        dispatch({
            type: 'REMOVE_PRODUCT',
            payload: {
                id
            }
        });
    }, [dispatch]);

    const addProductQuantity = useCallback((id: string) => {
        dispatch({
            type: 'ADD_PRODUCT_QUANTITY',
            payload: {
                id
            }
        });
    }, [dispatch]);

    const removeProductQuantity = useCallback((id: string) => {
        dispatch({
            type: 'REMOVE_PRODUCT_QUANTITY',
            payload: {
                id
            }
        });
    }, [dispatch]);

    const setService = useCallback((service: boolean) => {
        dispatch({
            type: 'SET_SERVICE',
            payload: {
                service
            }
        });
    }, [dispatch]);

    const applyCoupon = useCallback((coupon: Coupon) => {
        dispatch({
            type: 'APPLY_COUPON',
            payload: {
                coupon
            }
        });
    }, [dispatch]);

    const setOrganisation = useCallback((organisation: boolean) => {
        dispatch({
            type: 'SET_ORGANISATION',
            payload: {
                organisation
            }
        });
    }, [dispatch]);

    const setLocale = useCallback((locale: string) => {
        dispatch({
            type: 'SET_LOCALE',
            payload: {
                locale
            }
        });
    }, [dispatch]);

    const setCartImage = useCallback((imageBase64: string) => {
        dispatch({
            type: 'SET_CART_IMAGE',
            payload: {
                imageBase64
            }
        });
    }, [dispatch]);


    const clearCart = useCallback(() => {
        dispatch({
            type: 'CLEAR_CART'
        });
    }, [dispatch]);

    const helpers: CartHelpers = useMemo(() => ({
        addProduct,
        updateProduct,
        removeProduct,
        addProductQuantity,
        removeProductQuantity,
        setService,
        applyCoupon,
        setOrganisation,
        setLocale,
        setCartImage,
        clearCart
    }), [addProduct, updateProduct, removeProduct, addProductQuantity, removeProductQuantity, setService, applyCoupon, setOrganisation, setLocale, setCartImage, clearCart]);

    const context: CartContextType = useMemo(() => ({
        cart,
        totalProductPrice,
        servicePrice,
        servicePriceInclVat,
        servicePriceMonthly,
        servicePriceMonthlyInclVat,
        totalPrice,
        totalPriceInclVat,
        seatingCount,
        imagePreparationPrice,
        discount,
        ...helpers
    }), [cart, totalProductPrice, servicePrice, servicePriceInclVat, servicePriceMonthly, servicePriceMonthlyInclVat,
        totalPrice, totalPriceInclVat, seatingCount, imagePreparationPrice, discount, helpers]);

    return (
        <CartContext.Provider value={context}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = React.useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a LanguageSwitcherProvider');
    }

    return context;
};
