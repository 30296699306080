import React, { FC, useEffect } from 'react';
import { Claim, Right, Role } from 'utils/constants';
import { ClaimModel } from 'hooks/api/account/types';
import { setCookie } from 'utils/cookieHelper';
import { fullName } from 'utils/stringHelper';
import { userCookieName } from 'utils/constants/cookieConstants';

export interface Session {
    userId?: string;
    userName?: string;
    role?: Role;
    rights: Right[];
    firstName?: string;
    insertion?: string;
    lastName?: string;
    isOrganisation?: boolean;
}

export interface SessionContextValue {
    session?: Session;
    setSession: (claims: ClaimModel[]) => Session;
    clearSession: () => void;
    hasRight: (right: Right) => boolean;
    hasRole: (role: Role) => boolean;
}

export const convertToSession = (claims: ClaimModel[]) => {
    function claimValues(claimName: Claim) {
        const claim = claims.find((c) => c.type === claimName);
        return claim
            ? claim.values
            : undefined;
    }

    function claimFirstValue(claimName: Claim) {
        const values = claimValues(claimName);
        return values
            ? values[0]
            : undefined;
    }

    const userId = claimFirstValue(Claim.UserId);
    const userName = claimFirstValue(Claim.UserName);
    const role = claimFirstValue(Claim.Roles) as Role;
    const rights = claimValues(Claim.Rights) as Right[];
    const firstName = claimFirstValue(Claim.FirstName);
    const insertion = claimFirstValue(Claim.Insertion);
    const lastName = claimFirstValue(Claim.LastName);
    const isOrganisation = claimFirstValue(Claim.IsOrganisation) === 'True';

    const newSession: Session = {
        userId,
        userName,
        role,
        rights,
        firstName,
        insertion,
        lastName,
        isOrganisation
    };

    return newSession;
};

const SessionContext = React.createContext<SessionContextValue>({
    session: undefined,
    setSession: (claims: ClaimModel[]) => {
        return convertToSession(claims);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearSession: () => { },
    hasRight: (right: Right) => false,
    hasRole: (role: Role) => false,
});

interface SessionProviderProps {

}

export const SessionProvider: FC<SessionProviderProps> = (props) => {
    const [session, setSession] = React.useState<Session>();

    useEffect(() => {
        if (session != null) {
            setCookie(userCookieName, JSON.stringify({
                fullName: fullName(session?.firstName, session?.insertion, session?.lastName),
                role: session?.role
            }));
        } else {
            setCookie(userCookieName, '');
        }
    }, [session]);

    const updateSession = React.useCallback((claims: ClaimModel[]) => {
        const newSession = convertToSession(claims);
        setSession(newSession);

        return newSession;
    }, [setSession]);

    const clearSession = React.useCallback(() => {
        setSession(undefined);
    }, [setSession]);

    const hasRight = React.useCallback((right: Right) => {
        return session?.rights?.includes(right) ?? false;
    }, [session]);

    const hasRole = React.useCallback((role: Role) => {
        return session?.role === role ?? false;
    }, [session]);

    const context: SessionContextValue = React.useMemo(() => ({
        session,
        setSession: updateSession,
        clearSession,
        hasRight,
        hasRole,
    }), [session, updateSession, clearSession, hasRight, hasRole]);

    return (
        <SessionContext.Provider value={context} {...props} />
    );
};

export const useSession = () => {
    const context = React.useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};

