import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';

// Templates
const Default = lazy(() => import('components/templates/Default'));
const Account = lazy(() => import('components/templates/Account'));
const Dashboard = lazy(() => import('components/templates/Dashboard'));

// Pages
const Login = lazy(() => import('components/pages/account/Login'));
const Logout = lazy(() => import('components/pages/account/Logout'));
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));
const Register = lazy(() => import('components/pages/account/Register'));

const CompleteInvitationAgent = lazy(() => import('components/pages/account/CompleteInvitation/Agent'));
const CompleteInvitationCustomer = lazy(() => import('components/pages/account/CompleteInvitation/Customer'));

const Home = lazy(() => import('components/pages/default/Home'));
const Old = lazy(() => import('components/pages/default/Old'));

const Cart = lazy(() => import('components/pages/cart/Cart'));
const Checkout = lazy(() => import('components/pages/cart/Checkout'));
const Complete = lazy(() => import('components/pages/cart/Complete'));
const OtherScreenWarning = lazy(() => import('components/pages/cart/OtherScreenWarning'));

const DashboardHome = lazy(() => import('components/pages/dashboard/Dashboard'));

const Routes = () => {
    return (
        <Suspense fallback="">
            <Router>
                <Default default>
                    <Home default />
                    <Home path=":id" />
                    <Old path="/old" />
                    <Cart path="cart" />
                    <Checkout path="cart/checkout" />
                    <Checkout quotation path="cart/checkout/quotation" />
                    <Complete path="cart/complete/:orderId" />
                    <OtherScreenWarning path="cart/otherScreenWarning" />
                </Default>
                <Account path="account">
                    <Login path="login" />
                    <Logout path="uitloggen" />
                    <ForgotPassword path="wachtwoord-vergeten" />
                    <ResetPassword path="wachtwoord-instellen" />
                    <Register path="registreren" />
                    <CompleteInvitationAgent path="registratie-afronden/agent/:code" />
                    <CompleteInvitationCustomer path="registratie-afronden/customer/:code" />
                </Account>
                <Dashboard path="dashboard">
                    <DashboardHome default />
                </Dashboard>
            </Router>
        </Suspense>
    );
};

export default Routes;
