import colors from './colors';
import borderRadius from './borderRadius';
import navigation from './navigation';
import header from './header';
import greys from './greys';

const theme = {
    colors,
    greys,
    borderRadius,
    navigation,
    header,
    breakpoints: [400, 600, 900, 1200, 1550]
};

export default theme;
