import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import LocizeBackend from 'i18next-locize-backend';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(LocizeBackend)
    .init({
        fallbackLng: 'nl',
        debug: process.env.NODE_ENV === 'development',
        defaultNS: 'common',
        backend: {
            projectId: 'fbd1b1c6-4a20-44f4-9023-d5145604ba14'
        },
        load: 'currentOnly',
        interpolation: {
            escapeValue: false
        },
        ns: ['common']
    });

export default i18n;
