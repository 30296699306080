export interface Language {
    name: string;
    label: string;
}

export const languages: Language[] = [
    { name: 'nl', label: 'netherlands' },
    { name: 'en-GB', label: 'english' },
    { name: 'de-DE', label: 'german' },
    { name: 'fr-FR', label: 'french' },
    { name: 'es-ES', label: 'spanish' },
];
