import ReactDOM from 'react-dom';
import App from './App';
import { Expivi } from 'hooks/expivi/types';

declare global {
    interface Window { expivi: Expivi }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
