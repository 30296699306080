import React, { FC, useState } from 'react';

export type Position = 'center' | 'right' | 'left';

export interface LanguageSwitcherContextValue {
    setPosition: (position: Position) => void;
    position: Position;
}

const LanguageSwitcherContext = React.createContext<LanguageSwitcherContextValue>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPosition: () => { },
    position: 'right'
});

export const LanguageSwitcherProvider: FC = ({ children }) => {
    const [position, setPosition] = useState<Position>('right');

    return (
        <LanguageSwitcherContext.Provider value={{ position, setPosition }}>
            {children}
        </LanguageSwitcherContext.Provider>
    );
};

export const useLanguageSwitcher = () => {
    const context = React.useContext(LanguageSwitcherContext);
    if (!context) {
        throw new Error('useLanguageSwitcher must be used within a LanguageSwitcherProvider');
    }

    return context;
};
