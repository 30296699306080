import { StrictMode } from 'react';
import theme from './theme';
import { Global, css, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import grid from 'theme/grid';
import colors from 'theme/colors';
import Routes from './Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'contexts/sessionContext';
import 'i18n';
import { CartProvider } from 'contexts/cartContext';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { LanguageSwitcherProvider } from 'contexts/languageSwitcherContext';

setConfiguration(grid);

const styles = css`
    ::selection {
        background: ${colors.dark};
        color: ${colors.white};
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    html {
        overflow-x: hidden;
    }

    body {
        margin: 0;
        background-color: ${colors.white};
        color: ${colors.dark};
        font-size: 15px;
        line-height: 1.6;
        font-weight: 500;
        @media(max-width: 768px) {
            font-size: 14px;
        }
    }

    body, button, input, textarea {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
    }

    table {
        border: 0;
        border-collapse: collapse;
        width: 100%;
    }

    button {
        cursor: pointer;
        background: none;
        border: 0;
        outline: 0;
        padding: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    img {
        vertical-align: middle;
    }

    ul, p {
        margin-top: 0;
    }

    .icon-wrapper {
        pointer-events: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            vertical-align: middle;
            * {
                fill: inherit;
            }
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    h1, h2, h3, h4 {
        margin-top: 0;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 1.2;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.1875rem;
    }

    h3 {
        font-size: 1.733rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5, h6 {
        margin-top: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 0;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const queryClient = new QueryClient();

const App = () => {

    return (
        <StrictMode>
            <ScreenClassProvider>
                <Global styles={styles} />
                <ThemeProvider theme={theme}>
                    <ModalProvider rootComponent={TransitionGroup}>
                        <SessionProvider>
                            <CartProvider>
                                <QueryClientProvider client={queryClient}>
                                    <LanguageSwitcherProvider>
                                        <Routes />
                                    </LanguageSwitcherProvider>
                                </QueryClientProvider>
                            </CartProvider>
                        </SessionProvider>
                    </ModalProvider>
                </ThemeProvider>
            </ScreenClassProvider>
        </StrictMode>
    );
};

export default App;
