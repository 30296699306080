export interface Colors {
    primary: string;
    // secondary: string;
    success: string;
    error: string;
    warning: string;
    dark: string;
    white: string;
    black: string;
    light: string;
    medium: string;
    border: string;
    green: string;
    lightGreen: string;
    red: string;
    lightRed: string;

}

const colors: Colors = {
    primary: '#F26531',
    // secondary: '#EB9532',
    success: '#12BF63',
    error: '#F03745',
    warning: '#CE5800',
    dark: '#3A3A3A',
    white: '#ffffff',
    black: '#000000',
    light: '#ECECE4',
    medium: '#9B9B93',
    border: '#D6D6D6',
    green: '#4AAD24',
    lightGreen: '#D7F7CB',
    red: '#D66B6B',
    lightRed: '#FDDADA'

};

export default colors;
