export enum Claim {
    UserId = 'api.sitandheat.userId',
    UserName = 'api.sitandheat.userName',
    Roles = 'api.sitandheat.role',
    Rights = 'api.sitandheat.right',
    FirstName = 'api.sitandheat.firstName',
    Insertion = 'api.sitandheat.insertion',
    LastName = 'api.sitandheat.lastName',
    IsOrganisation = 'api.sitandheat.isOrganisation'
}
