import { TFunction } from 'react-i18next';
import { numberToCurrency } from 'utils/stringHelper';

export const calculateBasePrice = (value: number, isOrganisation: boolean) => {
    return value * (isOrganisation ? 1 : 1.1);
};

export const calculateInclVat = (value: number, vatPercentage = 21) => {
    return value + (value * (vatPercentage / 100));
};

export const determinePrice = (isOrganisation: boolean, priceExclVat: number, priceInclVat: number) : string => {
    return numberToCurrency(isOrganisation ? priceExclVat : priceInclVat);
};

export const getVatLabel = (isOrganisation: boolean, translation: TFunction<string>) : string => {
    return `(${(translation(`common:${isOrganisation ? 'vatExclusive' : 'vatInclusive'}`))})`;
};
