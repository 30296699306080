import i18n from 'i18n';

export const fullName = (firstName?: string, insertion?: string, lastName?: string): string => {
    return [firstName, insertion, lastName].filter(Boolean).join(' ');
};

export const fullAddress = (street?: string, houseNumber?: string, postalCode?: string, city?: string, country?: string): string | null => {
    if (street == null || houseNumber == null || postalCode == null || city == null || country == null) {
        return null;
    }

    const streetHouseNumber = [street, houseNumber].filter(Boolean).join(' ');
    const postalCodeAndCity = [postalCode, city].filter(Boolean).join(' ');

    return [streetHouseNumber, postalCodeAndCity, country].filter(Boolean).join(', ');
};

export const currentCurrency = () => {
    switch (i18n.language) {
        case 'en-GB':
            return 'GBP';
        default:
            return 'EUR';
    }
};

export const numberToCurrency = (value: number, currency?: string): string => {
    return value.toLocaleString(i18n.language, {
        style: 'currency',
        currency: currency ?? currentCurrency()
    });
};
