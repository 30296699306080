import Cookies from 'js-cookie';

export const setCookie = (name: string, value: string) => {
    const domainParts = document.domain.split('.');

    if (domainParts.length > 1) {
        domainParts.shift();
    }

    Cookies.set(name, value, { domain: domainParts.join('.') });
};
